import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import { logout } from '../../slices/auth';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'


function DrawerAppBar(props) {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () =>{
    dispatch(logout())
    .then(()=>{
      navigate('/login')
    })
  }

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar component="nav" sx={{background: '#B6E2E8', color: '#000', py: 3, boxShadow:'0px 2px 10px #f1f1f1'}}>
      <Box sx={{display:'flex', justifyContent:'center'}} >
          <img src={Logo}></img>
        </Box>
      </AppBar>
    </Box>
  );
}

export default DrawerAppBar;