import React from "react";
import { Container, Typography, Grid, Button } from "@mui/material";
import "./Category.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";

const Registration = () => {
  return (
    <div className="content">
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ fontWeight: "bold", textAlign:'center' }}>
             Registration
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Name
                </Typography>
                <FormControl
                  sx={{ my: 1, background: "#B6E2E8" }}
                  fullWidth
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Email
                </Typography>
                <FormControl
                  sx={{ my: 1, background: "#B6E2E8" }}
                  fullWidth
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Phone Number
                </Typography>
                <FormControl
                  sx={{ my: 1, background: "#B6E2E8" }}
                  fullWidth
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Company or Event Name
                </Typography>
                <FormControl
                  sx={{ my: 1, background: "#B6E2E8" }}
                  fullWidth
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Company or Event Name
                </Typography>
                <FormControl
                  sx={{ my: 1, background: "#B6E2E8" }}
                  fullWidth
                  variant="outlined"
                >
                  <TextField
          id="outlined-multiline-flexible"
          multiline
          rows={4}
        />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Link to="registration">
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ background: "#52B4AE", my: 3, py: 2 }}
                  >
                    Get A Quote
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Registration;
