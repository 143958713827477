import React from 'react';
import './Footer.css'
import { Typography } from '@mui/material';

const Footer = () => {

  return (
    <div className='footer'>
      <Typography sx={{textAlign:'center'}}>
      Powered by NexArc Information Technology
      </Typography>
    </div>
  )
}

export default Footer