import React from "react";
import './Category.css';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Typography } from "@mui/material";
import Cups from '../../assets/images/cups.png'
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Category = () => {
  return (
    <div className="content">
      <Container maxWidth="md">
      <Box sx={{ flexGrow: 1 }}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Typography className="headLine" variant="h5" sx={{fontWeight: 'bold'}}>
          Category
        </Typography>
        </div>
        <Box sx={{my: 12}}>
        <Grid container rowSpacing={10}  columnSpacing={3}>
        {Array.from(Array(10)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Link to='/detail' style={{textDecoration:'none'}}>
            <Item>
              <Grid container>
                <Grid item xs={5} sx={{background:'#B6E2E8', py: 7, position:'relative'}}>
                    <img src={Cups} style={{position: 'absolute', top:'-47px', left: '20px'}} width={130}></img>
                </Grid>
                <Grid item xs={7}  sx={{ py: 7}}>
                    <Typography variant="h5" sx={{fontWeight: 'bold', color:'#000'}}>
                      Printed Paper Cup
                    </Typography>
                </Grid>
              </Grid>
            </Item>
            </Link>
          </Grid>
        ))}
        </Grid>
        </Box>
      </Box>
      </Container>
    </div>
  );
};

export default Category;
