import { Container, Box, Typography, Grid, Button } from '@mui/material'
import React from 'react'
import './Category.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Cups from '../../assets/images/cups.png';
import CloudUpload from '../../components/button/Cloudupload';
import { Link, Outlet} from 'react-router-dom';

const CategoryDetail = () => {


  return (
    <>
    <div className='detailbg'>
        </div>
        <Box sx={{pt: 18}}>
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                   <img src={Cups} width={400}></img>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h4' sx={{fontWeight: 'bold'}}>
                        Printed Paper Cup
                    </Typography>
                    <Grid spacing={2} sx={{marginTop: 2}}>
                        <Grid xs={12}>
                            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                * Product
                            </Typography>
                            <FormControl sx={{ my: 1, background:'#B6E2E8'}} fullWidth variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                * Printing Style
                            </Typography>
                            <FormControl sx={{ my: 1, background:'#B6E2E8'}} fullWidth variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                * Quantity
                            </Typography>
                            <Paper elevation={0} sx={{color:'#fff', width: "150px", height:"50px", lineHeight:"19px", background:'#52B4AE', display: "flex", justifyContent:"space-between", alignItems:"center", my: 1}}>
                                  <Button sx={{minWidth: 0}}
                                  
                                  ><RemoveIcon fontSize="small" sx={{color: "#fff"}}/></Button>
                                  0
                                  <Button sx={{minWidth: 0}}><AddIcon sx={{color: "#fff"}} fontSize="small" /></Button>
                                  </Paper>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                * If you have artwork, please upload hear
                            </Typography>
                            <Box sx={{my: 1}}>
                            <CloudUpload/>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                * Delivery Deadline
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker sx={{
        width:'100%',
        background:'#52B4AE',
        color:'#fff',
        my: 1
    }}/>

    </LocalizationProvider>
                        </Grid>
                        <Grid xs={12}>
                            <Link  to='registration'>
                            <Button variant='contained' fullWidth sx={{background:'#52B4AE', my: 3, py: 2}}>Continue</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        </Box>
        <Outlet/>
        </>
  )
}

export default CategoryDetail